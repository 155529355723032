@import 'src/_constants' 
  
.uspGridContainer
  display : grid
  grid-gap: 10px
  padding: 25px
  justify-content: center
  background: #F1F7F9

.gridElement
  justify-content: space-between
  margin : 30px 15px

  
.imageDiv
  img
    width: 120%
    max-width: 140px

  flex: 0 0 28%
  display: flex
  justify-content: center
  align-items: center

.textDiv
  flex: 0 0 69%
  display: flex
  align-items: center

  h4
    font-family:  $fontFamily
    font-size: 18px
    font-style: normal
    font-weight: 500
    line-height: 27px
    letter-spacing: 0em
    text-align: left
    color: #000 !important
    margin-left : 8px

@media screen and (max-width: 590px)
  .textDiv h4
    font-size: 12px
    font-style: normal
    font-weight: 600
    line-height: 18px
    letter-spacing: 0em

  .uspGridHeader h2

  .gridElement
    margin : 0px
     

@media screen and (min-width:591px) and (max-width:1024px)
  .uspGridHeader h2
   