
$buttonColor1: #E2D868
$secHeaderColor: rgb(30 131 148)
$secHeaderFontSize: 36px
$secHeaderFontSizeMob: 18px
$fontFamily: 'Poppins'
$primary: #4bafc1
$secondary: #f2f2f2

@mixin page-header-desktop
    font-size: 48px
    font-weight: 500
    line-height: 72px
    font-family: $fontFamily

@mixin page-header-tab
    font-size: 48px
    font-weight: 500
    line-height: 72px
    text-align: center
    font-family: $fontFamily

@mixin page-header-mobile
    font-size: 30px
    font-weight: 500
    line-height: 54px
    text-align: center
    font-family: $fontFamily

@mixin page-subHeader-desktop
    font-size: 24px
    font-weight: 600
    line-height: 36px
    font-family: $fontFamily
    margin: 10px 0
    font-style: normal
    letter-spacing: 0.05em
    color: #000000
    margin-bottom: 15px

@mixin page-subHeader-mobile
    text-align: center
    font-size: 18px
    line-height: 27px
    text-align: center
    font-family: $fontFamily
    font-weight: 600
    margin: 10px 0
    color: #000000

@mixin page-subHeader-tab
    font-size: 20px
    line-height: 36px
    text-align: center
    font-family: $fontFamily
    font-weight: 600
    margin: 10px 0
    color: #000000

@mixin section-header-desktop
    font-size: 36px
    font-weight: 600
    line-height: 54px
    text-align: center
    font-family: $fontFamily

@mixin section-header-mobile
    font-size: 18px
    font-weight: 600
    line-height: 24px
    font-family: $fontFamily
    width : 90%

@mixin section-header-tab
    font-size: 20px
    font-weight: 600
    line-height: 30px
    font-family: $fontFamily

@mixin cta-button
    width: 230px
    height: 54px
    font-size: 15px
    font-family: $fontFamily
    background-color: $buttonColor1
    border: none
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14)
    border-radius: 2px
    color: #000
    font-weight: 600
    font-style: normal
    line-height: 54px
    letter-spacing: 0.5px
    text-align: center
    text-transform: uppercase

@mixin cta-button-mobile
    height: 40px
    line-height : 45px
    width: 192px
    border-radius: 4px
    background-color: $buttonColor1
    font-size: 14px
    font-family: $fontFamily
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)
    border-radius: 4px
    color: #000
    font-weight: 600
    text-transform: uppercase

@mixin slick-arrows
    width: 30px
    height: 30px
    z-index: 9

@mixin slick-arrow-prev
    left: -3px
    text-align: left

@mixin slick-arrow-next
    right: 0px
    text-align: right

@mixin flexCenter
    display: flex
    justify-content: center
    align-items: center
