@import 'src/_constants'
.sectionHeader h1
  font-family: $fontFamily
  @include section-header-desktop
  letter-spacing: 0em
  margin: 15px auto
  color: #1E8394
  text-align: center
  h1
    font-weight : bold


@media only screen and (max-width: 590px)
  .sectionHeader h1
    @include section-header-mobile
    letter-spacing: 0em
    text-align: center


@media screen and (min-width: 591px) and (max-width: 1024px)
  .sectionHeader h1
    @include section-header-tab