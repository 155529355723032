@import 'src/_constants'
.sectionHeader
  grid-column: 1/13

  h2
    margin-top: 72px
    margin-bottom: 36px

.pricingPageSubHeaderContainer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  font-family: $fontFamily
  font-size: 18px
  font-weight: 600
  grid-column: 1/13
  margin-bottom: 72px

  .title
    font-size: 24px
    max-width: 90%

.pricingPageCurriculumContainer
  display: grid
  grid-template-columns: repeat(12, 1fr)
  grid-auto-rows: auto
  background-color: rgb(238 244 246)
  padding: 25px 0px

.curriculumCardsContainer
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr))
  grid-gap: 20px
  grid-column: 2/12

#ageSelect
  border: initial
  border-bottom: 1px solid rgb(74 74 74 /50%)
  border-radius: initial
  font-family: $fontFamily
  font-size: 16px
  background: transparent

@media screen and (max-width: 713px)
  .curriculumCardsContainer
    grid-template-columns: repeat(1,1fr)

  .pricingPageAgeGroupContainer
    font-size: 12px

  #ageSelect
    font-size: 12px
