@import '../../../_constants';

.mobile-page-banner-heading-container {
	display: none;
	background: white;
	position: relative;
	z-index: 10;
	height: 80px;
	width: 100%;
	justify-content: center;
	align-items: center;
	box-shadow: 0 1px 2px #80808052;
	h1 {
		@include page-header-desktop;
		font-size: 36px;
		text-align: center !important;
		span {
			&:nth-child(1) {
				color: #018093;
			}
			&:nth-child(2) {
				color: #49c5e4;
			}
		}
	}
}

.bannerContainer {
	z-index: -1;
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;

	.slick-initialized {
		overflow: hidden;
	}

	.slick-slide {
		& > div {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 525px;
				height: auto;
				border-radius: 10px;
			}
		}
	}
	.slick-dots {
		bottom: 0px !important;
	}

	.slick-dots li button:before {
		font-size: 14px;
	}

	.bannerSlider {
		flex: 0 0 46%;
		max-height: 550px;

		.slick-prev {
			@include slick-arrows;
			@include slick-arrow-prev;
		}

		.slick-next {
			@include slick-arrows;
			@include slick-arrow-next;
		}

		.slick-prev:before,
		.slick-next:before {
			font-size: 25px;
			color: rgb(10 10 10 /25%);
		}
	}

	.bannerTextContainer {
		flex: 0 0 46%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		.textInfo {
			height: 60%;
			display: flex;
			justify-content: space-evenly;
			flex-direction: column;
			.button-Wrapper {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
			.button {
				@include cta-button;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			h1 {
				@include page-header-desktop;
				color: #1e8394;
				span {
					&:nth-child(1) {
						color: #018093;
					}
					&:nth-child(2) {
						color: #49c5e4;
					}
				}
			}
			h2 {
				@include page-subHeader-desktop;
			}

			ul {
				margin-bottom: 15px;
			}
			li {
				font-size: 18px;
				font-weight: 600;
				line-height: 24px;
				font-family: $fontFamily;
				color: black;
				margin-bottom: 0.1rem;
			}
		}
	}
}

.modal .bookingFormContainer {
	background: transparent;
	padding: 20px;
}

.modal-card-body {
	background: transparent;
	.delete {
		position: absolute;
		right: 5px;
		height: 44px;
		max-height: initial;
		max-width: initial;
		width: 44px;
		background-color: rgb(107 159 169);
	}

	.crmWebToEntityForm {
		background: linear-gradient(180deg, #fff, rgb(158 226 239));
	}
}

@media screen and (max-width: 768px) {
	.mobile-page-banner-heading-container {
		display: flex;
	}

	.bannerContainer {
		flex-direction: column-reverse;
		.bannerTextContainer {
			.textInfo {
				text-align: center;
				min-height: 250px;
				h1 {
					display: none;
				}
				h2 {
					@include page-subHeader-mobile;
				}
				.button {
					@include cta-button-mobile;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 10px auto 25px;
				}
				ul {
					display: block;
					margin: 0 auto;
					li {
						text-align: left !important;
						font-size: 14px;
					}
				}
			}
		}
		.bannerSlider {
			width: 95% !important;
			height: auto !important;
			margin-top: 25px !important;
			.slick-slide {
				& > div {
					img {
						width: 80% !important ;
						height: auto !important ;
					}
				}
			}
			.slick-dots {
				display: none !important;
			}
		}
	}
}
