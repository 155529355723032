@import "src/_constants.sass"
.keyPillarContainer
  align-items: center
  background: white

  padding: 80px 0px
  flex-direction: column
  justify-content: space-evenly

.keyPillarHeading
  font-family: $fontFamily
  margin: 40px auto
  font-style: normal
  font-weight: bold
  font-size: 36px
  line-height: 49px
  text-align: center
  color: #A51E7A

.keyPillarSubHeading
  font-family: $fontFamily
  font-size: 24px
  font-style: normal
  font-weight: 700
  line-height: 28px
  letter-spacing: 0em
  text-align: center
  color: #58595C
  margin: 20px

.pillarsWrapper
  align-items: center
  justify-content: space-evenly
  padding: 25px 0

@media only screen and (max-width: 768px)
  .keyPillarContainer
    background-color: #EEF4F6
    padding: 30px 0px

  .keyPillarHeading
    font-family: $fontFamily
    font-size: $secHeaderFontSizeMob
    font-style: normal
    font-weight: bold
    line-height: 27px
    letter-spacing: 0em
    text-align: center
    margin: 20px auto

  .keyPillarSubHeading
    font-family: $fontFamily
    font-size: 12px
    font-style: normal
    font-weight: 700
    line-height: 18px
    letter-spacing: 0em
    text-align: center

  .pillarsWrapper
    flex-direction: column !important
    max-height: initial !important
