@import 'src/_constants'
.pillarsSlider
  flex: 0 0 40%

.mobileHeading
  display: none
mobileHeading
.pillarsWrapper .slick-initialized
  overflow: hidden
.pillarsWrapper
  video , iframe
    object-fit: fill
    max-height: 517px
    max-width: 525px

.pillarsSlider

  .slick-slide > div
    display: flex
    justify-content: center
    align-items: center
    display: flex
    justify-content: center
    align-items: center
    background: transparent !important

  .slick-prev
    @include slick-arrows
    @include  slick-arrow-prev

  .slick-next
    @include slick-arrows
    @include  slick-arrow-next

  .slick-prev:before, .slick-next:before
    font-size: 25px
    color: rgb(25 160 185 /50%)

  img
    max-height: 436px
    min-height: 250px
    border-radius: 10px

.pillarsTextContainer
  width: 100%
  height: 100%
  flex: 0 0 45%
  justify-content: center
  align-items: center

.textCard
  min-height: 350px
  max-height: 450px
  padding: 15px
  display: flex
  flex-direction: column
  justify-content: space-between
  font-family: $fontFamily
  border-radius: 10px
  padding: 25px!important

  .button
    @include cta-button
    font-size: 18px

  h3
    font-style: normal
    font-weight: 700
    font-size: 24px
    line-height: 33px
    text-align: center

  p
    font-family: Poppins
    font-size: 16px
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: 0em
    text-align: left

  li
    font-family: Poppins
    font-size: 14px
    font-style: normal
    font-weight: 500
    line-height: 21px
    letter-spacing: 0em
    text-align: left

  ul
    list-style: disc
    padding-inline-start: 30px

@media screen and (max-width: 768px)
  .mobileHeading
    display: block
    text-align: center
    width: 100%
    font-style: normal
    font-weight: 700
    font-size: 20px
    line-height: 33px
    text-align: center
    margin-bottom: 18px

    h3
      font-size: 18px
      font-weight: 700
      margin-bottom: 5px

  .pillarsSlider
    width: 75vw

    img
      width: 100%
      max-width: 400px

  .pillarsTextContainer
    margin-top: 10px
    display: flex
    justify-content: center
    align-items: center

  .textCard
    display: flex
    border-radius: 10px
    max-height: initial
    min-height: 200px
    width: 75vw
    padding: 20px 0px
    box-shadow: initial
    text-align: center

    h3
      display: none

    p, li
      font-size: 12px
      line-height: 22px
      padding: 8px

    p
      text-align: left
    .button
      margin-top: 10px
      @include cta-button-mobile
