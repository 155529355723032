@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Mountains+of+Christmas&family=Varela+Round&display=swap')

*
  margin: 0
  padding: 0
  box-sizing: border-box

.lock-scroll
  overflow: hidden
  height: 100vh

.blur
  filter: blur(3px)

body
  min-height: 100vh
  margin: 0 auto

.demoFormFocus
  div
    label
      transform: translate(0px, 0px) scale(1)
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms

    input:focus
      outline: none
    input:focus ~ label, input:valid ~ label,  input:disabled ~ label
      transform: translate(0px, -28px) scale(.95)

.demoFormChecked
  input:checked ~ label
    color: #1E8394

.burger-icon
  position: relative
  height: 3px
  width: 25px
  transition: all 0.2s ease-in-out
  transform: rotate(180deg)

  &::before,
  &::after
    content: ''
    position: absolute
    height: 3px
    width: 25px
    background: inherit
    transition: all 0.2s ease-in-out
    margin-top: -8px

  &::before
    margin-top: 8px

.burger-icon.open
  height: 0

  &::before
    transform: rotate(-45deg)
    inset: 0
    margin: 0

  &::after
    transform: rotate(45deg)
    inset: 0
    margin: 0

.App
  text-align: center

.App-logo
  height: 40vmin
  pointer-events: none

nav#navBar + div
  padding-top: 76px

@media (prefers-reduced-motion: no-preference)
  .App-logo
    animation: App-logo-spin infinite 20s linear

  .App-header
    background-color: #282c34
    min-height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    font-size: calc(10px + 2vmin)
    color: white

  .App-link
    color: #61dafb
.dVYIqU
  bottom: 55px !important

.center-body
  position: relative
  margin-top: 50px

  @media ( max-width: 450px )
    margin-top: 50px

@keyframes App-logo-spin
  from
    transform: rotate(0deg)

  to
    transform: rotate(360deg)
@media screen and (min-width: 1281px)
  .dVYIqU
    bottom: 55px !important
