@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.eclipse_23 {
		background: linear-gradient(
			180deg,
			rgba(196, 196, 196, 0) 9.62%,
			#bfbfc0 100%
		);
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
	}
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	justify-content: center;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#root {
	max-width: 1280px;
	flex: 1;
	overflow: hidden;
}

* {
	font-family: Poppins, sans-serif;
}
