.testimonial-card{
    left: 0;
    right: 0;
    margin: auto;
    width : 302px;
    height : 311px;
    .content{
        height : 257px;
        width : 237px;
        border: 3px dashed #84CAD6;
        border-radius: 10px;
        padding : 18px;
        & > img{
            height : 30px;
            width : auto;
        }
        p{
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
        }
        & > p{
            margin : 18px 0;
        }
        .footer{
            bottom : 18px;
            .image-wrapper{
                height : 56px;
                width : 56px;
                margin-right : 15px;
                border-radius : 50%;
            }
            .parent-info{
                .name{
                    font-weight : 600;
                    font-size : 14px;
                    color: #018093;
                }
                .relation{
                    font-weight : 600;
                }
            }
        }
    }
}