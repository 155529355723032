@import 'src/_constants'

.pricingPageContainer
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background: url(https://d37772fpgg0hig.cloudfront.net/assets/images/280604-P66YZG-138%201.webp), url(https://d37772fpgg0hig.cloudfront.net/assets/images/Rectangle+335.webp)
  background-size: cover
  padding: 20px 30px
  padding-bottom: 0
  background-blend-mode: screen

  h1
    @include page-header-desktop
    font-family: $fontFamily
    color: #1E8394
    text-align: center
    margin: 176px 0px 54px
    font-size: 64px

  p
    font-family: $fontFamily
    @include page-subHeader-desktop
    font-weight: 600
    letter-spacing: 0em
    text-align: center
    font-size: 20px
    text-align: center
    margin: 0px 160px

@media only screen and (max-width: 590px)
  .pricingPageContainer
    h1
      @include page-header-mobile

    p
      @include page-subHeader-mobile

@media only screen and (min-width: 591px) and (max-width: 1023px)
  .pricingPageContainer
    h1
      @include page-header-tab

    p
      @mixin page-subHeader-tab
