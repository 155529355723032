.pricingUspGridElement {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 80px;
}
.pricingUspGridPic {
	display: flex;
	justify-content: center;
	flex: 0 0 40%;
}
.pricingUspGridPic img {
	width: 90%;
	max-width: 130px;
	border-radius: 10px;
}

.pricingUspGridText {
	flex: 0 0 58%;
}
.pricingUspGridText p {
	font-family: 'Poppins';
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
}

.pricingUspGridText h3 {
	font-family: 'Poppins';
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 36px;
	letter-spacing: 0em;
	text-align: left;
	color: #1e8394;
}

@media screen and (max-width: 590px) {
	.pricingUspGridText p {
		font-size: 12px;
		line-height: 18px;
	}
	.pricingUspGridPic img {
		max-width: 72px;
	}
}
