.testimonial-container{
    height : 925px;
    width : 100vw;
    h1{
        display: flex;
        justify-content: center;
        font-family: Bubblegum Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 0.03em;
      padding-top: 30px;
      padding-bottom: 10px;
    }
    .testimonial-wrapper{
        margin: auto;
        width : 1000px;
    
    }
    .mob-view{
        display: none;
    }
    @media(max-width: 903px){
        .testimonial-wrapper{
            display: none;
        }
        .mob-view{
            display: block;
        }
    }
}