.testimonials-container {
  width: 100%;
  padding: 35px 0;
  .testimonials-wrapper {
    width: 100%;
    margin: 50px 0;
    .slide {
      transform: scale(0.8) !important;
      transition: 0.5s;
    }

    .activeSlide {
      transform: scale(1) !important;
      transition: 0.5s;
    }
  }
}

@media (max-width: 1200px) {
  .testimonials-container {
    .testimonials-wrapper {
      .slide {
        transform: scale(0.7) !important;
      }

      .activeSlide {
        transform: scale(0.9) !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .testimonials-container {
    .testimonials-wrapper {
      .activeSlide,
      .slide {
        transform: scale(0.7) !important;
      }
      // .slide{
      //     transform: scale(0.5)!important;
      // }

      // .activeSlide{
      //     transform: scale(0.7)!important;
      //     margin : 0 0 0 -15px
      // }
    }
  }
}

@media (max-width: 650px) {
  .testimonials-container {
    .testimonials-wrapper {
      .activeSlide,
      .slide {
        transform: scale(1) !important;
      }

      // .slide{
      //     transform: scale(0.4)!important;
      // }

      // .activeSlide{
      //     transform: scale(0.5)!important;
      // }
    }
  }
}

@media (max-width: 490px) {
  .testimonials-container {
    .testimonials-wrapper {
      .activeSlide,
      .slide {
        transform: scale(0.8) !important;
      }

      // .slide{
      //     transform: scale(0.1)!important;
      // }

      // .activeSlide{
      //     transform: scale(0.4)!important;
      // }
    }
  }
}
