@import "../../../../_constants.sass";

.TestimonialCard-container {
  width: 386px;
  height: 441px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(186, 183, 183, 0.37) 0%,
    rgba(186, 183, 183, 0) 36.05%,
    rgba(54, 51, 51, 0.75) 68.23%,
    rgba(0, 0, 0, 0.85) 100%
  );
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position : relative;
  .text-container {
    width: 100%;
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: white;
    padding: 25px;
    h1 {
      font-size: 70px;
      margin-bottom: -15px;
      color: yellowgreen;
      z-index: 2;
    }
    p {
      text-align: left;
      z-index : 2;
      &:nth-child(1) {
        width: 300px;
      }
    }
    .writter {
      text-align: right;
      margin-top: 5px;
    }
  }
  .TestimonialCard-wrapper {
    z-index: -5;
    width: 100%;
    height: 100%;
    background-position: bottom;
    border-radius: 10px;
    overflow: hidden;
    position : absolute;
  }
}
