@import 'src/_constants'

.stickyFooter
  display: none

.stickyFooter button
  @include cta-button

@media screen and (max-width: 768px)

  .fixedActive
    position: fixed
    bottom: 0
    left: 0
    width: 100vw
    background: rgb(10 10 10 / 40%)
    min-height: 65px
    max-width: 1280px
    display: flex !important
    justify-content: center
    align-items: center

  .stickyFooter button
    @include cta-button-mobile
