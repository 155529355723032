.curriclumSubjectCard {
  padding: 8px 20px;
  border-radius: 8px;
  box-shadow: 4px 4px 10px -2px rgb(128 128 128 /50%);
}
.curriclumSubjectCard h2 {
  margin: 20px auto 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}

.cardQuoteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardQuote {
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
  letter-spacing: 0em;
  width: 100%;
  margin: 10px auto;
  text-align: center;
}
.curriculumPoints {
  margin: 10px auto;
}
.curriculumPoints ul {
  list-style: disc;
  padding-inline-start: 20px;
}
.curriculumPoints li {
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin: 15px auto 0;
}
